<template>
  <div
    class="row position-relative w-100 m-0 p-0"
    style="z-index: 100"
    :style="getPageDesign()"
  >
    <div
      class="bg lazyload"
      :data-bg="MainImgSrc(getImageSrc(pageDesign.imageBlock , isDesktopView))"
      v-if="pageDesign.pageStyles.imageAlign == 'no'"
     
:key="getImageSrc(pageDesign.imageBlock , isDesktopView)"
:style="
getbackgroundImage(
IsImageAdded,
pageDesign.imageBlock,
pageDesign.pageStyles.imageAlign , isDesktopView
) + getBgDivStyles()
"
    ></div>
    <!-- BackGroundDiv end-->
    <div v-if="showAlert" class="AlertContainer">
      <div
        class="alert text-center fade show text-white alert-text"
        role="alert"
        style="background: #f27367; width: fit-content"
      >
        <span>
          {{ alertMsg }}
        </span>
      </div>
    </div>
    <!-- Image Align Left -->
    <div
      class="px-0"
      v-show="showLeftImage"
      :class="[
        {
          'col-12 col-lg-12 col-xs-12': !isDesktopView,
          'image-division col-6': isDesktopView,
        },
      ]"
      :style="
        calculateImageWidth(
          pageDesign.imageBlock,
          isDesktopView,
          IsImageVariableWidthActive
        )
      "
    >
      <div :class="isDesktopView ? 'image-division' : ''">
        <div :style="height100(isDesktopView)">
          <LazyImage
            :style="
              getUniversalImageStyle(
                isDesktopView,
                pageDesign.imageBlock.style,
                IsImageVariableWidthActive,
                pageDesign.imageBlock
              ) +
              getImageRotation(pageDesign.imageBlock.style) +
              getImageOpacity(pageDesign.imageBlock.style)
            "
            :class="[
              `pic-BaldenStep1`,
              getImageSrc(pageDesign.imageBlock , isDesktopView) == '/images/default_start_page.png'
                ? 'default-bg'
                : '',
            ]"
            :src="MainImgSrc(getImageSrc(pageDesign.imageBlock , isDesktopView))"
            alt
          />
        </div>
      </div>
    </div>

    <!-- Main Section -->
    <div
      :class="[!IsImageAdded ? 'col-12 pr-4 pr-md-0' : imageAddedLayout]"
      :style="
        getMainPaddingCss(pageDesign.pageStyles, isDesktopView) +
        'height:630px!important; scroll:auto;' +
        calculateContentWidth(
          pageDesign.imageBlock,
          isDesktopView,
          IsImageVariableWidthActive,
          pageDesign.pageStyles
        )
      "
    >
      <div
        style="
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          height: 100%;
        "
      >
        <div style="overflow: auto" class="h-100">
          <div
            v-for="(pro, ind) in pageDesign.blocksArray"
            :key="'preview' + ind"
            class=""
          >
            <div
              id="preview"
              :style="getHeightWidth(pro.style)"
              class="content"
              style="position: relative"
            >
              <div
                v-if="pro.type == 'text'"
                :style="
                  getColorCss(pro.style) +
                  getPaddingCss(pro.style) +
                  getMarginCss(pro.style, isDesktopView) +
                  getColorWithOpacity(pro.style) +
                  getTextRotate(pro.style) +
                  getLineHeight(pro.style) +
                  getTextAlign(pro.style) +
                  getFontStyle(pro.style)
                "
                v-html="getTextValue(translateCustomText(pro.content) )"
              ></div>

              <!-- Free Text Input -->

              <div v-if="pro.type == 'freeText'" class="px-2 px-md-0">
                <div
                  :style="
                    getMarginCss(pro.style, isDesktopView) +
                    getTextAlign(pro.style)
                  "
                >
                  <div class="mb-1" v-if="pro.showLabel">
                    <p class="m-0 p-0 text-left font-weight-bold">
                      {{ translateCustomText( pro.labelText) }}
                      <span v-if="pro.isRequired" style="color: red">*</span>
                    </p>
                  </div>
                  <div
                    :style="
                      pro.style.borderType === 'border'
                        ? getInputBorderCSS(pro.style)
                        : getInputBorderBottomCSS(pro.style)
                    "
                  >
                    <input
                      v-if="
                        pro.selectedInfoType.fieldName !== 'date' &&
                        pro.selectedInfoType.fieldName !== 'phone_number'
                      "
                      :type="pro.selectedInfoType.type.toLowerCase()"
                      :placeholder="translateCustomText( pro.InfoTypePlaceholder)"
                      @keyup.enter="elthInitiateOtp(pro.selectedInfoType.type)"
                      :required="pro.isRequired"
                      class="text-dark font-weight-medium customBaldenInput CustomTextInput"
                      :style="customInputStyles(pro.style)"
                      v-model="createTitle"
                    />
                  </div>
                </div>
              </div>

              <div v-if="pro.type == 'freeTextArea'" class="px-2 px-md-0">
                <div
                  :style="
                    getMarginCss(pro.style, isDesktopView) +
                    getTextAlign(pro.style)
                  "
                >
                  <div class="mb-1" v-if="pro.showLabel">
                    <p class="m-0 p-0 text-left font-weight-bold">
                      {{ translateCustomText( pro.labelText )}}
                      <span v-if="pro.isRequired" style="color: red">*</span>
                    </p>
                  </div>
                  <div
                    class="bg-white p-2"
                    :style="
                      pro.style.borderType === 'border'
                        ? getInputBorderCSS(pro.style)
                        : getInputBorderBottomCSS(pro.style)
                    "
                  >
                    <Editor
                      :productDescription="pro.LeadTextValue"
                      @updateDescription="updateDescription($event)"
                      :placeholder="translateCustomText( pro.InfoTypePlaceholder)"
                      class="customBaldenTextAreaInput customBaldenInput p-1"
                    ></Editor>
                   
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- LEad Ans Back Button -->
        <div>
          <div
            :style="getHeightWidth(StaticButtonElement.style)"
            class="content"
          >
            <div class="w-100 ">
              <div
                class="d-flex mt-2"
                :style="
                  getBackNextButtonPosition(
                    StaticButtonElement,
                    CurrentPage.value
                  )
                "
              >
                <button
                  style="z-index: 1000"
                  :style="
                    getCommonButtonCSS(
                      StaticButtonElement.style,
                      isDesktopView
                    ) +
                    getBackNextButtonCss(
                      StaticButtonElement.content.backButton.style,isDesktopView
                    )
                  "
                  @click="backPage"
                  v-show="
                    !StaticButtonElement.content.backButton.hideBackButton
                  "
                >
                  <span
                    :style="{
                      opacity: `${StaticButtonElement.content.backButton.style.textOpacity}%`,
                      fontSize: `${StaticButtonElement.content.backButton.style.fontSize}px`,
                    }"
                    >{{ translateCustomText( StaticButtonElement.content.backButton.text )}}</span
                  >
                </button>
                <button
                  style="z-index: 1000"
                  :style="
                    getCommonButtonCSS(
                      StaticButtonElement.style,
                      isDesktopView
                    ) +
                    getBackNextButtonCss(
                      StaticButtonElement.content.nextButton.style,isDesktopView
                    )
                  "
                  @click="nextPage()"
                >
                  <span
                    v-if="!nextBtnSpinner"
                    :style="{
                      opacity: `${StaticButtonElement.content.nextButton.style.textOpacity}%`,
                      fontSize: `${StaticButtonElement.content.nextButton.style.fontSize}px`,
                    }"
                    >{{ translateCustomText( StaticButtonElement.content.nextButton.text )}}</span
                  >
                  <div
                    v-else
                    class="spinner-border spinner-border-sm"
                    role="status"
                  >
                    <span class="sr-only">Loading...</span>
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Image Align Right -->
    <div
      class="px-0"
      v-show="showRightImage"
      :class="[
        {
          'col-12 col-lg-12 col-xs-12': !isDesktopView,
          'image-division col-6': isDesktopView,
        },
      ]"
      :style="
        calculateImageWidth(
          pageDesign.imageBlock,
          isDesktopView,
          IsImageVariableWidthActive
        )
      "
    >
      <div :class="isDesktopView ? 'image-division' : ''">
        <div :style="height100(isDesktopView) + `height:103%;`">
          <LazyImage
            :style="
              getUniversalImageStyle(
                isDesktopView,
                pageDesign.imageBlock.style,
                IsImageVariableWidthActive,
                pageDesign.imageBlock
              ) +
              getImageRotation(pageDesign.imageBlock.style) +
              getImageOpacity(pageDesign.imageBlock.style) +
              'height:650px!important;'
            "
            :class="[
              `pic-BaldenRightImage`,
              getImageSrc(pageDesign.imageBlock , isDesktopView) == '/images/default_start_page.png'
                ? 'default-bg'
                : '',
            ]"
            :src="MainImgSrc(getImageSrc(pageDesign.imageBlock , isDesktopView))"
            alt
          />
        </div>
      </div>
    </div>

    <!-- Show Logo Footer -->

    <!-- End -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import cssFunctionMixin from "../../../customize/mixin/cssFunction.js";
import TranslationMixin from "../../../customize/mixin/TranslationMixin";
export default {
  data() {
    return {
      nextBtnSpinner: false,
      showAlert: false,
      alertMsg: "",
      // Step 1 PageDesign Data
      pageDesign:{},
      createTitle:"",
      createDescription:""
    };
  },
  created(){
   this.pageDesign = {
        pageStyles: {
          overflow: "auto",
          activeBlockTitle: "Styles",
          activeSubBlock: false,
          paddingTop: "10",
          paddingRight: "10",
          paddingBottom: "10",
          paddingLeft: "10",
          marginLeft: 0,
          marginRight: 0,
          alignItems: "center",
          textAlign: "right",
          backgroundColor: "#F7F0ED",
          color: "black",
          imageAlign: "right",
          onlyBackgroundImg: false,
          imageFor: "both",
          imageSrc: "PAGE_SIDE_IMAGE_PATH",
          bgImageStatus: false,
          bgImageSrc: false,
          fontFamily: "",
          fontSize: "",
          height: "630",
          width: 850,
          jsCode:
            "//Add script without script tag\n//variables --> window.quizell.leadData, window.quizell.allQuestions, \n//window.quizell.selectedQuestionOptions , window.quizell.products.\nconsole.log(window.quizell.getters);",
        },
        blocksArray: [
          {
            type: "text",
            // content: this.translateCustomText("Select a title and a description for your new shop") ,
            content: "Select a title and a description for your new shop" ,
            questionTitle: true,
            style: {
              paddingLeft: 10,
              paddingRight: 10,
              paddingTop: 10,
              paddingBottom: 10,
              marginTop: "0",
              marginBottom: "0",
              marginLeft: "0",
              marginRight: "0",
              width: "800",
              height: "160",
              textOpacity: 100,
              color: "#503A3C",
              rotate: 0,
              lineHeight: "1.4",
              textDecoration: "unset",
              fontWeight: "bold",
              fontStyle: "normal",
              textAlign: "center",
              fontSize: "22",
              fontFamily: "Open Sans",
            },
            customizeStatus: false,
            hoverStatus: false,
          },
          {
            type: "text",
            // content: this.translateCustomText(`*Required fields`),
            content: `*Required fields`,
            questionTitle: false,
            style: {
              paddingLeft: 0,
              paddingRight: 0,
              paddingTop: 10,
              paddingBottom: 10,
              marginTop: 0,
              marginBottom: 0,
              marginLeft: 0,
              marginRight: 0,
              fontSize: 14,
              width: 700,
              height: 400,
              textOpacity: 100,
              color: "#503A3C",
              rotate: 0,
              lineHeight: "1.5",
              textDecoration: "unset",
              fontWeight: "normal",
              fontStyle: "italic",
              textAlign: "center",
              fontFamily: "Open Sans",
            },
            customizeStatus: false,
            hoverStatus: false,
          },
          {
            type: "freeText",
            // labelText: this.translateCustomText(`Title`),
            labelText: `Title`,
            showLabel: true,
            isRequired: true,
            addInfoTypeEdit: false,
            newInfoType: "",
            infoType: [
              {
                text: "Full Name",
                type: "text",
                fieldName: "full_name",
                fieldValue: "",
              },
              {
                text: "Email",
                type: "email",
                fieldName: "email",
                fieldValue: "",
              },
              {
                text: "Phone Number",
                type: "number",
                fieldName: "phone_number",
                fieldValue: "",
              },
              {
                text: "Date",
                type: "number",
                fieldName: "date",
                dateText: "Date",
                monthText: "Month",
                yearText: "Year",
                fieldValue: "",
                dateValue: "",
                monthValue: "",
                yearValue: "",
              },
              {
                text: "Website",
                type: "text",
                fieldName: "website",
                fieldValue: "",
              },
              {
                text: "Organization",
                type: "text",
                fieldName: "organisation",
                fieldValue: "",
              },
            ],
            // InfoTypePlaceholder: this.translateCustomText(
            //   `Enter your shop title here (max 30 characters)`
            // ),
            InfoTypePlaceholder: 
              `Enter your shop title here`,
            style: {
              marginTop: "0",
              marginBottom: "0",
              marginLeft: "5",
              marginRight: "5",
              borderRadius: "0",
              textOpacity: 100,
              color: "#9C4629",
              textDecoration: "unset",
              fontWeight: "bold",
              fontStyle: "normal",
              textAlign: "left",
              fontSize: 14,
              fontFamily: "Poppins",
              strokeOpacity: 100,
              strokeColor: "#9C4629",
              strokeRotation: 0,
              borderSize: "1",
              borderType: "border",
              DateTextColor: "#000000",
              DateFontFamily: "Poppins",
              DateFontSize: 14,
              DateTextOpacity: 100,
              DateTextDecoration: "unset",
              DateFontWeight: "bold",
              DateFontStyle: "normal",
              DateTextAlign: "left",
            },
            customizeStatus: false,
            hoverStatus: false,
            selectedInfoType: {
              text: "Full Name",
              type: "text",
              fieldName: "full_name",
              fieldValue: "",
            },
          },

          {
            type: "freeTextArea",
            isRequired: true,
            isMaxCharacter: true,
            maxCharacterLength: 400,
            LeadTextValue: "",
            // labelText: this.translateCustomText(`Description`),
            labelText:`Description`,
            showLabel: true,
            InfoTypePlaceholder: 
              `Enter your shop description here (maximum 400 characters)`,
            // InfoTypePlaceholder: this.translateCustomText(
            //   `Enter your store description here (maximum 400 characters)`
            // ),
            style: {
              marginTop: "20",
              marginBottom: "0",
              marginLeft: "5",
              marginRight: "5",
              borderRadius: "0",
              textOpacity: 100,
              color: "#9C4629",
              textDecoration: "unset",
              fontWeight: "bold",
              fontStyle: "normal",
              textAlign: "left",
              fontSize: 14,
              fontFamily: "Poppins",
              strokeOpacity: 100,
              strokeColor: "#9C4629",
              strokeRotation: 0,
              borderSize: "1",
              borderType: "border",
              DateTextColor: "#000000",
              DateFontFamily: "Poppins",
              DateFontSize: 14,
              DateTextOpacity: 100,
              DateTextDecoration: "unset",
              DateFontWeight: "bold",
              DateFontStyle: "normal",
              DateTextAlign: "left",
            },
            customizeStatus: true,
            hoverStatus: false,
          },

          {
            type: "button",
            content: {
              backButton: {
                // text: this.translateCustomText(`Go Back`),
                text: `Go Back`,
                hideBackButton: true,
                style: {
                  backgroundColor: "#ffffff",
                  color: "#9C4629",
                  buttonWidth: "100",
                  buttonHeight: "40",
                  textOpacity: 100,
                  backgroundOpacity: "100",
                  textDecoration: "unset",
                  fontFamily: "Open Sans",
                  fontWeight: "bold",
                  fontStyle: "normal",
                  fontSize: "15",
                  borderRadius: "20",
                  borderSize: "1.5",
                  borderColor: "",
                  paddingLeft: 15,
                  paddingRight: 15,
                  paddingTop: 15,
                  paddingBottom: 15,
                  textAlign: "center",
                },
              },
              nextButton: {
                // text: this.translateCustomText(`Next`),
                text: `Save`,
                style: {
                  backgroundColor: "#9C4629",
                  color: "#ffffff",
                  buttonWidth: "100",
                  buttonHeight: "40",
                  textOpacity: 100,
                  backgroundOpacity: "100",
                  textDecoration: "unset",
                  fontFamily: "Open Sans",
                  fontWeight: "bold",
                  fontStyle: "normal",
                  fontSize: "15",
                  borderRadius: "20",
                  borderSize: "1.5",
                  borderColor: "",
                  paddingLeft: 15,
                  paddingRight: 15,
                  paddingTop: 15,
                  paddingBottom: 15,
                  textAlign: "center",
                },
              },
            },
            style: {
              position: "left",
              marginTop: "0",
              marginBottom: "20",
              marginLeft: "0",
              marginRight: "10",
              lineHeight: 1,
              buttonPosition: "end",
            },
            customizeStatus: false,
            hoverStatus: false,
            staticButton: true,
          },
        ],
        imageBlock: {
          // src: this.getPageImage,
          src: this.quizId == (this.getEnv?.VUE_APP_ITALIAN_QUIZID??null)  ? 'https://cdn.shopify.com/s/files/1/0724/0574/5977/files/Capture_d_ecran_2023-06-02_a_13.26.30.png?v=1685705223' : 'https://cdn.shopify.com/s/files/1/0571/0246/7222/files/Capture_d_ecran_2023-08-24_a_16.49.05.png?v=1692892211',
          customizeStatus: false,
          hoverStatus: false,
          useMobileImage: false,
        useMobileImageSrc:"/images/default_start_page.png",
          style: {
            paddingLeft: 0,
            paddingRight: 0,
            paddingTop: 0,
            paddingBottom: 0,
            textAlign: "center",
            backgroundColor: "none",
            width: 300,
            height: 650,
            rotate: 0,
            imageOpacity: "100",
            displayMobile: false,
            displayDesktop: true,
          },
          useImageVariableWidth: false,
          imageVariableWidth: 50,
        },
      }
  },
  mixins: [cssFunctionMixin, TranslationMixin],
  components: {
    Editor: () =>
      import(
        /* webpackChunkName: "Editor" */ "../../../../components/Editor.vue"
      ),
  },

  props: ["isDesktopView", "quizId"],

  computed: {
    ...mapGetters(["getEnvConst"]),
     getEnv(){
      // ...mapGetters(["getEnvConst"]),
      // getEnvConst
      return this.getEnvConst;
    },
    showLeftImage() {
      return (
        (this.isDesktopView &&
          this.pageDesign.imageBlock.style.displayDesktop &&
          this.pageDesign.pageStyles.imageAlign == "left") ||
        (!this.isDesktopView &&
          this.pageDesign.imageBlock.style.displayMobile &&
          (this.pageDesign.pageStyles.imageAlign == "left" ||
            this.pageDesign.pageStyles.imageAlign == "right"))
      );
    },
    IttalianQuizId(){
      return this.getEnv?.VUE_APP_ITALIAN_QUIZID??null
    },
    getPageImage(){
      return  this.quizId == this.IttalianQuizId  ? 'https://cdn.shopify.com/s/files/1/0724/0574/5977/files/Capture_d_ecran_2023-06-02_a_13.26.30.png?v=1685705223' : 'https://cdn.shopify.com/s/files/1/0571/0246/7222/files/Capture_d_ecran_2023-08-24_a_16.49.05.png?v=1692892211'
    },
    showRightImage() {
      return (
        this.isDesktopView &&
        this.pageDesign.imageBlock.style.displayDesktop &&
        this.pageDesign.pageStyles.imageAlign == "right"
      );
    },
    IsImageAdded() {
      if (this.getImageSrc(this.pageDesign.imageBlock , this.isDesktopView) !== "" || this.getUploadedImage)
        return true;
      return false;
    },
    IsImageVariableWidthActive() {
      return this.IsImageAdded && this.pageDesign.pageStyles.imageAlign != "no"
        ? true
        : false;
    },
    imageAddedLayout() {
      if (this.pageDesign.pageStyles.imageAlign == "no") return "col-12";
      if (!this.isDesktopView) return "col-12";
      return "col-12 col-xs-12 col-sm-6 col-md-6  ";
    },
    CurrentPage() {
      return "leadPage";
    },
    StaticButtonElement() {
      const index = this.pageDesign.blocksArray.findIndex(
        (x) => x.type == "button"
      );
      return this.pageDesign.blocksArray[index];
    },

  },
  watch: {
    createTitle(newVal, oldVal) {
   
        if (newVal.length > 30 ) {
        this.createTitle = oldVal;
      
      }
     
    },
    createDescription(newVal, oldVal) {
      
      if (newVal.length > 400) {
        this.createDescription =
          oldVal;
      }
    },
  },
  methods: {
    updateDescription(e) {
      this.createDescription = e;
    },
    getPageDesign() {
      let design =
        "text-align:" +
        this.pageDesign.pageStyles.textAlign +
        ";color:" +
        this.pageDesign.pageStyles.color +
        "; background:" +
        this.pageDesign.pageStyles.backgroundColor +
        "; width:" +
        this.pageDesign.pageStyles.width +
        "px;";

      return design;
    },

    getInputBorderCSS(property) {
      return (
        "border:" +
        property.borderSize +
        "px solid " +
        property.strokeColor +
        "; border-radius:" +
        property.borderRadius +
        "px; " +
        "transform: rotate(-" +
        property.strokeRotation +
        "deg);"
      );
    },
    getInputBorderBottomCSS(property) {
      return (
        "border-bottom:" +
        property.borderSize +
        "px solid " +
        property.strokeColor +
        "; border-radius:" +
        property.borderRadius +
        "px; " +
        "transform: rotate(-" +
        property.strokeRotation +
        "deg);"
      );
    },
    InputAdjust(e) {
      let Element = e.target;
      // Element.rows = 2;
      Element.rows = 1;
      const styles = window.getComputedStyle(Element);
      const paddingTop = parseInt(styles.paddingTop);
      const paddingBottom = parseInt(styles.paddingBottom);
      const padding = paddingTop + paddingBottom;
      const initialHeight = (parseInt(styles.height) - padding) / Element.rows;
      const scrollHeight = Element.scrollHeight - padding;
      const newRows = Math.ceil(scrollHeight / initialHeight);
      Element.rows = newRows - 1 == 0 ? 1 : newRows - 1;
    },
    backPage() {
      this.$emit("moveToStep1");
    },
    translateCustomText(key) {
      return this.translateToItalian(key, this.quizId);
    },
    nextPage() {
      if (this.createTitle !== "" && this.createDescription !== "") {
        this.$emit("updateTextDesc", {
          title: this.createTitle,
          desc: this.createDescription,
        });

        this.$emit("moveToStep2");
      } else {
        this.alertMsg = this.translateCustomText(
          `Please provide the required values`
        );

        this.showAlert = true;
        setTimeout(
          function () {
            this.showAlert = false;
            this.alertMsg = "";
          }.bind(this),
          3000
        );
      }
    },
  },
};
</script>

<style scoped>
.CustomTextInput {
  /* color: var(--inputColor) !important; */
  color: #503a3c !important;
}
.customBaldenInput {
  width: 100%;
  background-color: #fff;
  border: none;
  outline: none;
}
.customBaldenInput::placeholder {
  color: var(--inputPlaceholderColor);
  font-size: 14px;
}

#baldenTextArea {
  width: 100%;
  height: 100%;
  background-color: #fff;
  border: none;
  outline: none;
  font-weight: normal !important ;
  font-size: 16px !important;
  color: #503a3c !important;
}

#baldenTextArea::placeholder {
  color: var(--inputPlaceholderColor);
  font-weight: normal;
}

.customBaldenInput .el-tiptap-editor__content .ProseMirror p {
  color: var(--inputPlaceholderColor);
  font-weight: normal;
}
</style>
<style>
.el-tiptap-editor__placeholder.el-tiptap-editor--empty:first-child:before,
.el-tiptap-editor__with-title-placeholder:first-child:before,
.el-tiptap-editor__with-title-placeholder:nth-child(2):before {
  color: #9c4629;
  font-weight: normal;
  font-family: Poppins, "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  content: attr(data-empty-text);
  float: left;
  height: 0;
  pointer-events: none;
}
</style>